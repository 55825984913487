import React, { FC } from 'react';

import { ICustomDots } from './model';

const CustomDot: FC<ICustomDots> = ({ onClick, ...rest }) => {
  const { active, index, customDots } = rest;

  return (
    <li>
      <button
        aria-label={customDots[index].ariaDot}
        type="button"
        className={`react-multi-carousel-cdot ${active ? 'active' : ''}`}
        onClick={onClick}
      >
        {customDots[index].year}
      </button>
    </li>
  );
};

export default CustomDot;
