import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';

import { NavigatorProps } from 'components/ControlledCarousel/model';

const NextArrow: FC<NavigatorProps> = ({ controller, allItemsCount, ariaLabel }) => {
  const currentSlide = controller?.state?.currentSlide || 0;
  const slidesToShow = controller?.state?.slidesToShow || 0;
  const isDisabled =
    allItemsCount - 1 === currentSlide || currentSlide >= allItemsCount - slidesToShow;

  const moveNext = useCallback(() => {
    controller?.next();
  }, [controller]);

  return (
    <Button
      ariaLabel={ariaLabel}
      variant="primary"
      type="button"
      disabled={isDisabled}
      clickHandler={moveNext}
      classes={classNames('carousel-navigator', 'carousel-navigator__next', {
        'carousel-navigator--disabled': isDisabled,
      })}
    >
      <IconCustom icon="chevron-right" />
    </Button>
  );
};
export default NextArrow;
