import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';

import { NavigatorProps } from 'components/ControlledCarousel/model';

const PrevArrow: FC<NavigatorProps> = ({ controller, ariaLabel }) => {
  const currentSlide = controller?.state?.currentSlide || 0;
  const isDisabled = currentSlide === 0;

  const movePrev = useCallback(() => {
    controller?.previous();
  }, [controller]);

  return (
    <Button
      ariaLabel={ariaLabel}
      variant="primary"
      type="button"
      disabled={isDisabled}
      clickHandler={movePrev}
      classes={classNames('carousel-navigator', 'carousel-navigator__prev', {
        'carousel-navigator--disabled': isDisabled,
      })}
    >
      <IconCustom icon="chevron-left" />
    </Button>
  );
};
export default PrevArrow;
